import Vue from 'vue'
import VueResource from 'vue-resource'
import { mapState, mapGetters } from 'vuex'
import { store } from '../store'
import cartMixin  from '../mixins/cart_mixin'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

const el = document.getElementById("gy-mini-cart")

if (el) {
  new Vue({
    el: el,
    store,
    components: {
      Coupon: () => import('../components/coupon.vue'),
      LineItem: () => import('../components/line_item.vue')
    },
    mixins: [cartMixin],
    computed: {
      ...mapState([
        'order',
        'recentlyAdded'
      ]),
      ...mapGetters([
        'apiPath',
        'signedIn'
      ])
    },
    mounted() {
      if (this.$el.attributes) {
        this.$store.commit('setMiniCartVersions', (this.$el.attributes.versions || {}).value)
        this.$store.commit('setCustomAttributesMiniCart', (this.$el.attributes['custom-attributes'] || {}).value)
        this.$store.commit('setOrderOptions', (this.$el.attributes['order-options'] || {}).value)
      }
      if (this.$el.id && this.$el.id.length > 0) {
        this.$store.dispatch('loadOrder')
      }
    }
  })
}
