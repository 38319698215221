import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { store } from '../store'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import VueCookie from 'vue-cookie'

axios.defaults.headers.common['x-csrf-token'] = document.querySelector('meta[name="csrf-token"]').content;
axios.defaults.headers.common['Authorization'] = window.api_token;
axios.defaults.headers.common['x-requested-with'] = 'XMLHttpRequest';

Vue.prototype.$eventbus = new Vue()
Vue.use(Vuelidate)
Vue.use(VueCookie)

const elements = document.getElementsByClassName("gy-app")

for (let el of elements) {
  new Vue({
    el: el,
    store,
    components: {
      SiteSelector: () => import('../components/site_selector.vue'),
      Search: () => import('../components/search/Search.vue'),
      Order: () => import('../components/orders/Order.vue'),
      Promotions: () => import('../components/promotions/promotions.vue'),
      Stores: () => import('../components/stores.vue'),
      GiftList: () => import('../components/gift_lists/GiftList.vue'),
      GiftLists: () => import('../components/gift_lists/GiftLists.vue'),
      GiftListForm: () => import('../components/gift_lists/GiftListForm.vue'),
      GiftListShare: () => import('../components/gift_lists/GiftListShare.vue'),
      Coupon: () => import('../components/coupon.vue'),
      ShoppingListsSummary: () => import('../components/shopping_lists/Summary.vue'),
      ShoppingListsFormCreate: () => import('../components/shopping_lists/FormCreate.vue'),
      Wishlist: () => import('../components/Wishlist.vue')
    },
    computed: {
      ...mapState([
        'order',
        'wishlistItems',
        'recentlyAdded'
      ]),
      ...mapGetters([
        'signedIn'
      ]),
      ...mapGetters('orders', ['summaryTotalQuantity', 'numberOfOrders']),
      cartTotalQuantity() {
        return this.order.total_quantity
      },
      wishlistQuantity() {
        return this.wishlistItems.length
      }
    }
  })
}
