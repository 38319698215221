import Vue from 'vue'
import Utils from '../lib/utils'

export const apiPath = (state) => {
  return `/${state.site_code}/${state.locale}/api/v2`
}

export const webPath = (state) => {
  return `/${state.site_code}/${state.locale}`
}

export const catalogPath = (state) => {
  return `/${state.locale}/catalog`
}

export const siteLocalePath = (state) => {
  return `/${state.site_code}/${state.locale}`
}

export const activeGiftList = (state) => {
  return Utils.detect(state.giftLists, state.activeGiftListId) || {}
}

export const signedIn = (state) => {
  return state.user && state.user.id > 0
}

export const stringifiedVersions = (state) => (key) => {
  let value = state.versions[key]
  if (value) return JSON.stringify(JSON.parse(value))
  return undefined
}

export const stringifiedOrderOptions = (state) => {
  let value = state.orderOptions
  if (value) {
    return JSON.stringify(JSON.parse(value))
  }
  return undefined
}
