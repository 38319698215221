// Load translations
import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource)

Vue.http.interceptors.push(function(request, next) {
  // modify headers
  if (document.querySelector('meta[name="csrf-token"]'))
    request.headers.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)

  request.headers.set('Authorization', window.api_token)
  // continue to next interceptor
  next()
})

// Lib
import Utils    from './lib/utils'
import NiceI18n from './lib/nice_i18n'

// Filters
import './filters/uppercase.js'
import './filters/capitalize.js'
import './filters/currency.js'
import './filters/round_percentage.js'
import './filters/integer.js'

// Apps
import miniCart             from './apps/mini_cart'
import cart                 from './apps/cart'
import app                  from './apps/app'
import loginForm            from './apps/login_form'
import registerForm         from './apps/register_form'
import checkout             from './apps/checkout'
import bundles              from './apps/bundles'
import address              from './apps/address'
import savedOrdersList      from './apps/saved_orders_list'
import savedOrdersNew       from './apps/saved_order_new'
import savedOrdersEdit      from './apps/saved_order_edit'
import mixNMatch            from './apps/mix_n_match'

// Classes
import Catalog              from './classes/catalog'
import Product              from './classes/product'
import MoadCookie           from './classes/moad/cookie'
import ConsentChecker       from './classes/moad/consent_checker'

// Catalog
export const catalogInstance = new Catalog

// Products
export const productInstance = new Product

/**
 * Triggers GY Analytics
 *
*/
window.triggerGYAnalytics = () => {
  // Register the event
  let event = new CustomEvent('gy::nicevueLoaded', {
    detail: {
      message: "Nicevue is ready for GY Analytics",
      time: new Date()
    },
    bubbles: true,
    cancelable: true
  })

  // Trigger the event
  document.dispatchEvent(event)

  // Set on window that nicevue has been loaded
  window.gy_data = window.gy_data || {}
  window.gy_data["nicevue_loaded"] = true
}

// MoaD
import MoadBase from './classes/moad/base'
export const moadBaseInstance = new MoadBase

export const GYCookie = new MoadCookie

window.consentChecker = new ConsentChecker

/**
 * Add our event listeners to document.
 *
*/
const bindGYEvents = () => {
  var gyEvents = [
    'gy::options-start-loading',
    'gy::options-loaded',
    'gy::photos-changed',
    'gy::option-variant-changed',
    'gy::added-to-cart',
    'gy::cannot-add-to-cart',
    'gy::user-needs-login',
    'gy::wishlist-loaded',
    'gy::wishlist-item-deleted',
    'gy::wishlist-deleted-all',
    'gy::wishlist-added-to-cart',
    'gy::wishlist-added-all-to-cart',
    'gy::added-to-wishlist',
    'gy::already-exists-in-wishlist',
    'gy::giftlist-created',
    'gy::giftlist-not-created',
    'gy::giftlist-updated',
    'gy::giftlist-product-added',
    'gy::giftlist-product-not-added',
    'gy::giftlists-loaded',
    'gy::giftlist-form-populated',
    'gy::giftlist-deleted',
    'gy::giftlist-item-added',
    'gy::giftlist-item-removed',
    'gy::giftlist-set-completed',
    'gy::giftlist-share',
    'gy::giftlist-set-completed-error',
    'gy::giftlist-share-link-success',
    'gy::giftlist-share-link-error',
    'gy::user-logged-in',
    'gy::user-not-authorized',
    'gy::user-registered',
    'gy::user-not-registered',
    'gy::app-mounted',
    'gy::catalog-visit-started',
    'gy::catalog-visit-done',
    'gy::catalog-paginate',
    'gy::catalog-last-page',
    'gy::coupon-activated',
    'gy::coupon-invalid',
    'gy::coupon-deactivated',
    'gy::order-loaded',
    'gy::line-item-deleted',
    'gy::line-item-quantity-updated',
    'gy::line-item-max-quantity-reached',
    'gy::payment-methods-loaded',
    'gy::shipping-methods-loaded',
    'gy::countries-loaded',
    'gy::regions-loaded',
    'gy::vat-offices-loaded',
    'gy::bundles-loaded',
    'gy::bundle-added-to-cart',
    'gy::bundle-cannot-add-to-cart',
    'gy::store-filter-selected',
    'gy::address-form-populated',
    'gy::address-deleted',
    'gy::address-error-deleted',
    'gy::address-updated',
    'gy::address-created',
    'gy::checkout-address-populated',
    'gy::promotions-loaded',
    'gy::promotion-added-to-cart',
    'gy::saved-orders-loaded',
    'gy::saved-order-created',
    'gy::saved-order-not-created',
    'gy::saved-order-loaded',
    'gy::saved-order-updated',
    'gy::saved-order-deleted',
    'gy::saved-order-added-to-cart',
    'gy::saved-line-item-already-exists',
    'gy::saved-order-no-line-items-on-save',
    'gy::saved-order-quick-added-to-cart',
    'gy::saved-order-quick-not-updated',
    'gy::saved-order-cart-saved',
    'gy::saved-order-cart-not-saved',
    'gy::notify-me-when-back-in-stock-started',
    'gy::notify-me-when-back-in-stock-completed',
    'gy::notify-me-when-back-in-stock-error',
    'gy::mix-n-match-item-taxonomy-changed',
    'gy::mix-n-match-item-products-loaded',
    'gy::mix-n-match-cannot-add-to-cart',
    'gy::mix-n-match-added-to-cart',
    'gy::product-store-availability-started',
    'gy::product-store-availability-completed',
    'gy::product-store-availability-error',
    'gy::product-mounted',
    'gy::product-list-loaded',
    'gy::orders-create-failed',
    'gy::orders-create-succeeded',
    'gy::orders-delete-failed',
    'gy::orders-delete-succeeded',
    'gy::orders-select-failed',
    'gy::orders-select-succeeded',
    'gy::orders-update-failed',
    'gy::orders-update-succeeded',
    'gy::cart-checker-success',
    'gy::cart-checker-failure',
    'gy::cart-checker-server-error'
  ]

  for (var i = 0; i < gyEvents.length; i++) {
    document.addEventListener(gyEvents[i], function(event) {
      var name = event.type.replace('gy::', '').replace(/-([a-z])/g, function (g) { return g[1].toUpperCase() })

      if (window.gyEvents && window.gyEvents[name]) {
        window.gyEvents[name](event)
        console.log(`[GY] Event \`${name}\` was fired!`)
      }
      else {
        console.log(`[GY] EVENT NOT FOUND => \`${name}\`. Please define it in the website!`)
      }
    })
  }
}

Utils.defer("gyEvents", () => {
  bindGYEvents()
})
