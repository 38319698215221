import Vue from 'vue'
import Utils from '../lib/utils'

export default {
  setGiftLists(state, payload) {
    state.giftLists = payload
  },

  setActiveGiftListId(state, payload) {
    state.activeGiftListId = payload
  },

  setAddress(state, payload) {
    // Weird syntax - see: https://github.com/tc39/proposal-object-rest-spread &
    // http://stackoverflow.com/questions/19837916/creating-object-with-dynamic-keys
    if (payload.address != undefined) {
      state[payload.type] = payload.address
    } else {
      state[payload.type] = {
        ...state[payload.type],
        [payload.key]: payload.value
      }
    }
  },

  setUser(state, payload) {
    state.user = payload
  },

  setRecentlyAdded(state, payload) {
    state.recently_added = payload
  },

  setOrderTitle(state, payload) {
    state.order.title = payload
  },

  setCustomAttributesMiniCart(state, payload) {
    state.customAttributesMiniCart = payload
  },

  setOrderOptions(state, payload) {
    state.orderOptions = payload
  },

  setWishlistItems(state, payload) {
    state.wishlistItems = payload
  },

  setMiniCartVersions(state, payload) {
    state.versions.miniCart = payload
  },

  setCartVersions(state, payload) {
    state.versions.cart = payload
  },

  setWishlistVersions(state, payload) {
    state.versions.wishlist = payload
  },

  setOrder(state, payload) {
    state.order = payload
  },

  setCoupon(state, payload) {
    state.coupon = payload
  },

  setAddresses(state, payload) {
    state.addresses = payload
  },

  setSameAsBilling(state, payload) {
    state.sameAsBilling = payload
  },

  setGiftCardValue(state, payload) {
    state.giftCardValue = payload
  },

  setCreditCardLoading(state, payload) {
    state.creditCardLoading = payload
  },

  setCreditCardResponse(state, payload) {
    state.creditCardResponse = payload
  },

  setCreditCard(state, payload) {
    state.creditCard = payload
  },

  setSimplify(state, payload) {
    state.simplify = payload
  },

  setHidePriceForProductGroupIds(state, payload) {
    state.hidePriceForProductGroupIds = payload
  },

  setQuantity(state, payload) {
    let lineItem = Utils.detect(state.order.line_items, payload.id)

    lineItem.quantity = payload.quantity
  },

  setPromotions(state, payload) {
    state.promotions = payload
  }
}
