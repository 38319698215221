import $ from 'jquery'
import { productInstance } from '../main'
import { store } from '../store'
import scroll from 'scroll'
import queryString from 'query-string'

var page = require('scroll-doc')()
var ease = require('ease-component')
export default class Catalog {
  constructor() {
    this.bindLoadMore()
    this.bindFilters()
    this.checkIfLastPage()
    this.bindRangeSliders()
  }

  checkIfLastPage() {
    if ($('.pagination').length > 0 && $('.pagination .next a').length == 0) {
      store.dispatch('triggerEvent', 'gy::catalog-last-page')
    }
  }

  bindLoadMore() {
    let $this = this

    if ($('.gy-load-more').length > 0) {
      $('.pagination').hide()

      $(document).on('click', '.gy-load-more', function(event) {
        event.preventDefault()
        let el  = $(this)
        let url = $('.pagination .next a').attr('href')

        // Prevent double clicking
        if (el.is("a[disabled]"))
          return false

        el.attr('disabled', 'disabled').addClass('loading')

        if (url) {
          $.ajax({
            url: url,
            data: {
              partially: true
            },
            dataType: 'script'
          }).done(response => {
            store.dispatch('triggerEvent', 'gy::catalog-paginate')

            productInstance.initializeAll()
            $this.checkIfLastPage()
            $('.pagination').hide()

            el.attr('disabled', false).removeClass('loading')
          })
        }
      })
    }
  }

  bindFilters() {
    let $this = this
    let selectors = '.gy-filters a, .gy-selected-filters a, .gy-sorting a, .gy-per a, .gy-pagination-wrapper a'

    if ($('.gy-filters').length > 0) {
      $(document).on('click', selectors, function(event) {

        event.preventDefault()
        $this.el  = $(this)
        $this.url = $this.el.attr('href')
        $this.loadCatalog()
      })
    }
  }

  bindRangeSliders() {
    let $this = this

    document.addEventListener('gy::range-slider-changed', function (event) {
      let parsed     = queryString.parseUrl(location.pathname + location.search)
      let filterName = event.detail.filterName
      let from       = event.detail.from
      let to         = event.detail.to

      let pathParts  = parsed.url.split('/')
      let exists     = false
      let parts      = []

      for (let part of pathParts) {
        if (part.indexOf(filterName) != -1) {
          parts.push(`${filterName}_${from}-${to}`)
          exists = true
        } else {
          parts.push(part)
        }
      }

      if (!exists) {
        parts.push(`${filterName}_${from}-${to}`)
      }

      if (parsed.query.length > 0) {
        $this.url = parts.join('/') + '?' + queryString.stringify(parsed.query, { sort: false })
      }
      else {
        $this.url = parts.join('/')
      }

      $this.loadCatalog()
    })
  }

  loadCatalog() {
    if (!this.url)
      return

    let $this = this

    productInstance.clearApps()

    store.dispatch('triggerEvent', 'gy::catalog-visit-started')

    $.ajax({
      url: this.url,
      dataType: 'script'
    }).done(response => {
      if (!$this.el || !$this.el.data('disable-push-state'))
        history.replaceState(null, '', $this.url)

      store.dispatch('triggerEvent', 'gy::catalog-visit-done')

      productInstance.initializeAll()

      $this.bindLoadMore()
      $this.checkIfLastPage()
      $this.scrollToTop()

      if ($('.gy-load-more').length > 0)
        $('.pagination').hide()
    })
  }

  // Scroll to top when changing page using
  scrollToTop() {
    if ($('.gy-catalog-wrapper').length > 0) {
      let currentPositionTop = window.pageYOffset || document.documentElement.scrollTop
      let positionToGo = $('.gy-catalog-wrapper').position()

      if (positionToGo && positionToGo.top > 0 && positionToGo.top + 200 < currentPositionTop) {
        scroll.top(page, positionToGo.top, {
          duration: 800,
          ease: ease.inOutSine
        })
      }
    }
  }
}
